@import 'vars/variables';

body {
	background-color: $accent-grey;
}
.body-page {
	// max-width: 1400px;
    margin: 0 auto;
    box-shadow: 0 0 60px 0 #000;
    overflow-x: hidden;
}
header { 
    .header-main {
        background: $header-grey;
    }
    ul li {
        list-style: none;
    }
}
nav {
    position: relative;
}
.sidebar-right {
    padding: 0;
}
// white text
.navigation .gva_menu > li > a {
	color: #fff;
}

// springboard teal 
#block-mainnavigation ul.gva_menu_main > li:last-child a,
h1, h2, h3, h4, h5, h6 {
	color: $springboard-teal;
}

#block-mainnavigation li:last-child a:hover {
    color: $accent-orange;
}


a {
    color: $springboard-teal;
    &:hover, &:active, &:focus {
        color: $accent-orange !important;
    }
}


// main content padding
.content-main-inner {
	padding: 30px 55px;
}
.frontpage .content-main-inner{
	padding: 0;
}

// .container {
// 	width: inherit;
// }


// header
// logo
#header .branding {
	max-width: 520px;
	position: absolute;
    top: 45px;
    left: 36px;
}
#block-gavias-mion-account-menu {
    a {
        padding: 25px 12px;
    }
    li:first-child:after {
        height: 20px;
        display: block;
        width: 2px;
        background: #fff;
        content: '';
        position: absolute;
        right: -1px;
        top: 22px;
    } 
}
#block-audience {
    margin: 75px 0 55px 0;
    display: inline-block;
    float: right;
}
#block-audience a {
    background: $accent-grey;
    padding: 10px 10px;
    margin: 0 6px;
    line-height: 1;
}
#block-mainnavigation {
	display: flex;
    width: 100%;
    justify-content: center;

    li {
        margin: 0 15px;
    }
}
#block-mainnavigation .gva_menu_main > li:last-child a {
    border: 4px solid;
    padding: 7px;
}

// member menu 
#block-membermenu {
    background: $accent-grey; 
    display: flex;
    justify-content: center;
    box-shadow: inset 0px 4px 10px -1px #333;
    @media (max-width: 991px) {
        display: none;
    }
    a {
        padding: 25px 12px;
    }

    .sub-menu {
        a {
            padding: 12px;
            line-height: 2;
        }
        .menu-item {
            padding: 0;
        }
    }
}

// slideshow
.frontpage .slideshow_content > div {
	position: relative;
}
#block-views-block-quote-slideshow-block-1-2 {
    position: absolute;
    top: 100px;
    right: 100px;
    max-width: 495px;
}
#block-views-block-quote-slideshow-block-1-2 p {
    font-size: 24px;
    color: #fff;
    line-height: 1.4;
    text-transform: uppercase;
    padding: 35px;
    text-align: center;
    letter-spacing: 0.4px;
}
#block-views-block-quote-slideshow-block-1-2 {

    .caption {
    	background: rgba(30,32,34,.8);
    }

    .video_link {
    	background: $header-grey;
    	text-align: right;
    	
    	a {
           color: $springboard-teal;
           font-size: 25px;
           line-height: 2;
           margin-right: 20px;

           &:after {
            content: "\f054";
            font-family: FontAwesome;
            font-size: 18px;
            padding-left: 8px;
            position: relative;
            top: -1px;
            }
        }
    }
}

#block-views-block-front-slideshow-block-1 {
    .field-content {
        text-align: center;
        background: $header-grey;
            img {
                width: 100%;
            }
    }
}

// programs
.programs {

    p, h3 a {
        color: #fff;
    }

    > h3 {
        font-size: 30px;
        margin: 30px 0;
    }

    .views-col {
        padding: 0;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 24px;
        max-width: 377px;
        display: flex;
    }

    .views-field-field-program-snippet {
        background: rgba(30, 32, 34, 0.6);
        position: absolute;
        height: 100%;
        width: 100%;
        
        .field-content {
            top: 25px;
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;

            p {
                font-size: 12px;
                line-height: 1.3;
                padding: 10px;
            }
        }
    }

    .views-field-title {
        background: rgba(66,69,71,.85);
        position: absolute;
        width: 100%;
        z-index: 9;
        
        .field-content {
            padding: 0px 10px;
            font-size: 16px;
            max-width: 272px;
        }
    }

    .views-field-field-award-date {
        position: absolute;
        right: 15px;
        z-index: 9;
        background: $springboard-teal;
        
        #award-container {
            text-align: center;
            padding: 6px;
            text-transform: uppercase;
            line-height: 1.2;
            font-size: 12px;
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
            color: #fff;
        }

        img {
            display: block;
            margin: auto;
        }
    }

    .views-view-grid .views-row {
        min-height: 262px;
    }

}

// award spotlight footer
#award-spotlight {
    padding: 1em;
    margin: 0 1em 1em 0em;
    background: $springboard-teal;

    @media (min-width: 992px) {
        margin: 0 2em 1em 2em;
    }
    min-height: 138px;

    h4 {
        margin-bottom: 0;
        color: #fff;
    }
}

#award-spotlight-info {
    padding: 1em;
    margin: 0 1em 1em 0;
    background: $accent-grey;
    min-height: 138px;
   
    h3 {
        margin-top: 0;
    }

    p {
        font-size: 12px;
        line-height: 1.3;
        color: #fff;
    }

    a {
        font-size: inherit;
        color: #fff;
    }
}

#award-spotlight-photo {
    padding: 0;
    @media (min-width: 768px) {
        width: 26%;
    }
    @media (min-width: 992px) {
        width: 17%;
    }
    text-align: center;
    margin: auto;
}

// photo gallery additions to inherited program styles
.photo-gallery {
    .views-col {
        margin: 0;
        padding: 0 10px;
        overflow: hidden;
    }
    .views-field-title {
        width: calc(100% - 20px);
        .field-content {
            max-width: inherit;
        }
    }
}

// photo gallery nodes
.block-views-blockphoto-gallery-block-1 {
    margin-bottom: 100px;
    .field-content {
        margin: 10px 10px 80px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 250px;
        background: #f6f6f6;
        border-radius: 2px;
    }
    @media (min-width: 768px) {
        .views-col.col-2, 
        .views-col.col-4 {
            position: relative;
            top: 150px;
        }
    }
}
// success stories
#block-views-block-success-stories-block-1 {
    background: $accent-grey;
    color: #fff;
    padding: 15px 0;
    p {
        line-height: 1.3;
        font-size: 12px;
    }
    .views-field-field-success-image {
        float: left;
        padding: 0 15px 15px;
    }
    .views-field-nothing {
        text-align: center;
        background: $springboard-teal;
            h3 {
                text-transform: uppercase;
                color: #fff;
                font-size: 18px;
                padding: 6px 0;
            }
    }
    .views-field-body {
        padding: 6px 15px;
    }
    .views-field-title p {
        margin-bottom: 10px;
        font-weight: bold;
    }

}
// leaders and members
.page-node-27 {
    #page-main-content {
        h2.block-title {
            margin-bottom: 0;
            span {
                color: inherit;
                font-size: inherit;
                padding-bottom: 0;
            }
        }
        table {
            border: none;
            tbody > tr > td {
                border: none;
                padding: 0;
            }
        }
    }
}

// directors 
#block-views-block-member-name-lists-block-1,
#block-views-block-member-name-lists-block-3 {
    .views-col {
        margin: 15px 0;
    }
    .views-field-profile-name {
        font-size: 16px;
        margin-top: 0;
        display: inline-block;
    }
    .views-field-field-position {
        opacity: 0.7;
        line-height: 1.4;
        font-size: 13px;
    }
    .views-field-user-picture {
        float: left;
        margin-right: 10px;
        position: relative;
        top: 7px;
        img {
            max-width: 66px;
        }
    }
}

// grantee application
// grantee main record
.node--type-grantee-application,
.node--type-grantee-main-record {
    article {
        fieldset {
            margin: 40px 0;
        }
        .field- {
            margin: 4px 0;
            padding: 4px;
            border-bottom: 1px solid #efefef;
            display: flex;
    
            & > div {
                align-items: center;
                display: flex;
            }
        }
        .field- > div > div {
            display: inline-block;
        }
        .field- > div > div:first-child {
            width: 290px;
            margin-right: 15px;
            display: table;
            font-weight: bold;
            text-transform: uppercase;
            &:after {
                content: ':';
            }
        }
    }
    .col-md-9 {
        @media (min-width: 992px) {
            width: 65%;
        }
    }
    .col-lg-3 {
        @media (min-width: 992px) {
            width: 35%;
        }
    }
}


// team pages 
.team-list {
    .views-field-nothing {
        background: #f6f6f6;
        padding: 15px 10px;
        .name p, span.name {
            font-weight: bold;
            background: #ececec;
            padding: 5px 10px;
            border-radius: 3px;
            display: block;
        }
        .more-info {
            padding: 10px 0 0 25px;
        }
        .more-info p {
            padding: 10px 0px 0px 10px;
        }
    }
}

// application docs
.file--application-pdf,
.file--x-office-document,
.file--x-office-spreadsheet,
.file--x-office-presentation {
    &:before {
        content: '\f15b';
        font-family: 'FontAwesome';
        display: inline-block;
        padding-right: 5px;
    }
}

// add grantee app button, action button
.action-button {
    .block-content {
        margin: 30px 0;
        display: inline-block;
        text-align: center;
        width: 100%;
        a {
            border: 4px solid;
            padding: 9px;
            border-radius: 3px;
        }
    }
}

/* --------------- Site Visit Styles --------------- */
.path-site-visit {
    .calendar-popup a {
      color: #fff;
      font-weight: bold;
      display: block;
      background: url(../images/btn_calendar_arrow_right.png) right top scroll no-repeat #0069aa !important;
      min-height: 15px;
      margin: auto;
      padding: 3px !important;
      background-position-y: 2px !important;
      &:hover {
        color: #fff;
      }
    }

    .site-visit-attending-No {
      background-color: #C9E9E8 !important;
      font-style: italic;
      color: #333;
      font-weight: normal !important;
    }

    .site-visit-attending-Yes {
      background-color: #ffc !important;
      font-style: italic;
      color: #333;
      font-weight: normal !important;
    }

    .site-visit-attending-button-Yes a {
      display: block;
      border: none;
      width: 143px;
      height: 26px;
      background: url(../images/popup_already_attend.png) 0 0 scroll no-repeat;
      cursor: pointer;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
    }

    .site-visit-attending-button-No a {
      display: block;
      border: none;
      width: 94px;
      height: 26px;
      background: url(../images/popup_attend.png) 0 0 scroll no-repeat;
      cursor: pointer;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
    }

    .site-visit-info-title h2 a {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    /*
    .site-visit-calendar-view #cboxWrapper, .site-visit-calendar-view #cboxContent {
        background: #0069AA !important;
    }

    .site-visit-calendar-view #cboxClose {
        background: url(../images/cross.png) no-repeat -25px 0px;
    }

    @todo
    Build JS to add custom class to colorbox elements

    */

    .calendar-calendar .month-view .full td.single-day div.monthview {
      padding: 0;
    }

    #cboxWrapper, #cboxContent {
      background: #0069AA !important;
      overflow: visible;
    }

    #cboxClose {
      background: url(../images/cross.png) no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      opacity: .7;
      &:hover {
        background-position: 0;
        opacity: 1;
      }
    }

    .site-visit-attending-button a:hover {
      background-position: 0 -26px !important;
      background-position: 0 -26px !important;
    }

    .site-visit-info-title {
      color: #0069AA !important;
      a:hover {
        text-decoration: underline;
        color: #ffffff !important;
      }
      .org-details {
        font-size: .7em;
        font-style: italic;
        font-weight: 300;
      }
    }

    .site-visit-info-body {
      table {
        border: none !important;
        margin-bottom: 10px;
      }
        border-radius: 5px;
        background: #fff;
        padding: 20px 0 0;
        overflow: hidden;
        margin: 20px 0;
        display: block;
    }

    .site-visit-buttons {
      background: #E5F4F3 !important;
      text-align: right;
      padding: 6px 10px;
      box-shadow: 0px 4px 20px -2px #929292;
      div {
        display: inline-block;
        vertical-align: middle;
        margin: 3px 5px;
      }
    }

    .site-visit-info-body {
      td {
        border: none;
      }
      .info-title {
        color: #0069AA !important;
      }
    }

    .site-visit-calendar-view .duplicate-field {
      display: none;
    }

    #cboxLoadedContent {
        overflow: visible !important; 
    }
}


.calendar.weekview {
    width: 96% !important;
}
// user management paths to allow large views to scroll
.path-users .main .container {
    overflow-x: scroll;
}


// ----------- Media Queries --------------

@media (max-width: 991px) {
    #header {
        .main-menu {
            padding: 0;
        }
        #block-audience {
            margin: 0;
            li {
                padding: 10px 0;
                a {
                    display: block;
                    text-align: center;
                }
            }
        }
        .branding {
            position: relative;
        }
        #block-gavias-mion-account-menu {
            padding: 15px 0;
            margin-right: 30px;
            li:first-child:after {
                display: none;
            }
            .gva_menu {
                text-align: right;
                .menu-item {
                    display: inline-block;
                }
            }   
        }
        .gva-search-region {
            top: 20px;
            right: 5px;
        }
        #menu-bar span{
            background-color: #fff;
        }
    }
    #block-views-block-quote-slideshow-block-1-2 {
        position: relative;
        top: 0;
        right: 0;
        max-width: inherit;
        background: url(../images/mobile_main.png);
        background-size: cover;
        background-position-x: 30%;
        .video_link {
            background: rgba(44, 47, 49, 0.95);
            text-align: center;
        }
        .caption p {
            padding: 80px 40px;
        }
    }
    #views_slideshow_cycle_main_front_slideshow-block_1 {
        display: none;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1450px;
    }
}

@media (max-width: 767px) {
    #header {
        .branding {
            top: 30px;
            left: 0;
            text-align: center;
            img {
                max-width: 360px;
            }
        }
        #block-gavias-mion-account-menu {
            position: absolute;
            top: 0;
            right: 0;
        }
        #block-audience {
            float: none;
            display: block;
            margin: 80px 50px 0;
        }
        .area-main-menu .area-inner {
            padding: 0;
        }
        #menu-bar {
            position: absolute;
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
    #header {
        .container {
            width: 1450px;
        }
        .branding {
            max-width: 400px;
            top: 80px;
        }
    }
}




/**
 * @file
 * Styling dropbuttons.
 */

/**
 * Reset styling for all elements.
 */

.js .dropbutton .dropbutton-action > {
  input, a, button {
    color: #333333;
    text-decoration: none;
    padding: 0;
    margin: 0;
    font-weight: 600;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    /* LTR */
  }
}

[dir="rtl"].js .dropbutton .dropbutton-action > {
  input, a, button {
    text-align: right;
    margin-left: 0;
    /* This is required to win over specificity of [dir="rtl"] .dropbutton-multiple .dropbutton .dropbutton-action > * */
  }
}

.js .dropbutton-action.last {
  border-radius: 0 0 0 1em;
  /* LTR */
}

[dir="rtl"] .js .dropbutton-action.last {
  border-radius: 0 0 1em 0;
}

/**
 * Overwrite Sevens button styling.
 */

.js {
  .dropbutton-widget .button {
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .dropbutton-multiple .dropbutton {
    border-right: 0;
    /* LTR */
  }
}

[dir="rtl"].js .dropbutton-multiple .dropbutton {
  border-left: 0;
}

/**
 * Show dropbutton elements as buttons when javascript is disabled
 */

.dropbutton {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li + li {
    margin-top: 10px;
  }
}

.js {
  .dropbutton li {
    margin-bottom: 0;
    margin-right: 0;
    + li {
      margin-top: 0;
    }
  }
  .dropbutton-multiple .dropbutton-widget {
    border: 1px solid #a6a6a6;
    border-radius: 20em;
    background-color: #f2f1eb;
    background-image: -webkit-linear-gradient(top, #f6f6f3, #e7e7df);
    background-image: linear-gradient(to bottom, #f6f6f3, #e7e7df);
    text-shadow: 0 1px hsla(0, 0%, 100%, 0.6);
  }
}

@media screen and (min-width: 37.5625em) {
  /* 601px */
  .dropbutton li {
    display: inline-block;
    + li {
      margin-left: 1em;
      margin-top: 0;
    }
  }
  .js .dropbutton li + li {
    margin-left: 0;
  }
}

/**
 * Copied styling for .button.
 */

.dropbutton-multiple.open .dropbutton-widget {
  border-radius: 1em;
}

.js .dropbutton-widget .dropbutton-action {
  a, input, button {
    border-radius: 20em 0 0 20em;
    /* LTR */
    padding: 4px 1.5em;
    display: block;
    width: 100%;
  }
}

[dir="rtl"].js .dropbutton-widget .dropbutton-action {
  a, input, button {
    border-radius: 0 20em 20em 0;
  }
}

.js {
  .dropbutton-widget .dropbutton-action {
    a:focus, input:focus, button:focus {
      text-decoration: underline;
    }
  }
  .dropbutton-multiple.open .dropbutton-action {
    a, .button {
      border-radius: 0;
    }
    &:first-child {
      a, .button {
        border-radius: 0.9em 0 0 0;
        /* LTR */
      }
    }
  }
}

[dir="rtl"].js .dropbutton-multiple.open .dropbutton-action:first-child {
  a, .button {
    border-radius: 0 0.9em 0 0;
  }
}

.js .dropbutton-multiple.open .dropbutton-action:last-child {
  a, .button {
    border-radius: 0 0 0 0.9em;
    /* LTR */
  }
}

[dir="rtl"].js .dropbutton-multiple.open .dropbutton-action:last-child {
  a, .button {
    border-radius: 0 0 0.9em 0;
  }
}

.js .dropbutton-wrapper .dropbutton-widget .dropbutton-action {
  a:hover, button:hover, input:hover, a:focus, button:focus, input:focus {
    background-color: #f9f8f6;
    background-image: -webkit-linear-gradient(top, #fcfcfa, #e9e9dd);
    background-image: linear-gradient(to bottom, #fcfcfa, #e9e9dd);
    color: #1a1a1a;
    box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.125);
    z-index: 3;
  }
  a:active, input:active, button:active {
    text-decoration: none;
    background-color: #dfdfd9;
    background-image: -webkit-linear-gradient(top, #f6f6f3, #e7e7df);
    background-image: linear-gradient(to bottom, #f6f6f3, #e7e7df);
    box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.2);
  }
}

.dropbutton .secondary-action {
  border-top: 1px solid #bfbfba;
}

/**
 * Rare instances when a dropbutton is actually just a button.
 * Copied from Seven's buttons.css.
 */

.dropbutton-single {
  .dropbutton-widget {
    border: 0;
    position: static;
    display: inline-block;
  }
  .dropbutton-action a {
    padding: 4px 1.5em;
    border: 1px solid #a6a6a6;
    border-radius: 20em !important;
    background-color: #f2f1eb;
    background-image: -webkit-linear-gradient(top, #f6f6f3, #e7e7df);
    background-image: linear-gradient(to bottom, #f6f6f3, #e7e7df);
    color: #333333;
    text-decoration: none;
    text-shadow: 0 1px hsla(0, 0%, 100%, 0.6);
    font-weight: 600;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    -webkit-font-smoothing: antialiased;
    width: auto !important;
    &:hover, &:focus {
      background-color: #f9f8f6;
      background-image: -webkit-linear-gradient(top, #fcfcfa, #e9e9dd);
      background-image: linear-gradient(to bottom, #fcfcfa, #e9e9dd);
      color: #1a1a1a;
      text-decoration: none;
      outline: none;
    }
    &:hover, &:focus {
      box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.125);
    }
    &:active {
      background-color: #dfdfd9;
      background-image: -webkit-linear-gradient(top, #f6f6f3, #e7e7df);
      background-image: linear-gradient(to bottom, #f6f6f3, #e7e7df);
      box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.2);
      -webkit-transition: none;
      transition: none;
    }
  }
}

/**
 * The dropdown trigger.
 */

.js .dropbutton-wrapper .dropbutton-widget .dropbutton-toggle button {
  border-left: 1px solid #a6a6a6;
  /* LTR */
  outline: none;
}

[dir="rtl"].js .dropbutton-wrapper .dropbutton-widget .dropbutton-toggle button {
  border-right: 1px solid #a6a6a6;
  border-left: 0;
}

.js .dropbutton-wrapper .dropbutton-widget .dropbutton-toggle button {
  border-radius: 0 20em 20em 0;
  /* LTR */
}

[dir="rtl"].js .dropbutton-wrapper .dropbutton-widget .dropbutton-toggle button {
  border-radius: 20em 0 0 20em;
}

.dropbutton-multiple.open .dropbutton-widget .dropbutton-toggle button {
  border-radius: 0 1em 1em 0;
  /* LTR */
}

[dir="rtl"] .dropbutton-multiple.open .dropbutton-widget .dropbutton-toggle button {
  border-radius: 1em 0 0 1em;
}

.js .dropbutton-wrapper .dropbutton-widget .dropbutton-toggle button {
  &:hover, &:focus {
    background-color: #f9f8f6;
    background-image: -webkit-linear-gradient(top, #fcfcfa, #e9e9dd);
    background-image: linear-gradient(to bottom, #fcfcfa, #e9e9dd);
    color: #1a1a1a;
    text-decoration: none;
    box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.125);
    z-index: 3;
  }
  &:active {
    background-color: #dfdfd9;
    background-image: -webkit-linear-gradient(top, #f6f6f3, #e7e7df);
    background-image: linear-gradient(to bottom, #f6f6f3, #e7e7df);
    box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.2);
  }
}

.dropbutton-arrow {
  border-top-color: #333;
  right: 35%;
  /* LTR */
  top: 54%;
}

[dir="rtl"] .dropbutton-arrow {
  left: 35%;
  right: auto;
}

.dropbutton-multiple.open .dropbutton-arrow {
  border-bottom: 0.3333em solid #333;
  border-top-color: transparent;
  top: 0.6667em;
}

/**
 * Form edit action theming.
 * Copied styling from .button--primary.
 */

.js .form-actions {
  .dropbutton .dropbutton-action > * {
    color: #fff;
    font-weight: 700;
    text-shadow: 0 1px hsla(0, 0%, 0%, 0.5);
  }
  .dropbutton-widget {
    border-color: #1e5c90;
    background-color: #0071b8;
    background-image: -webkit-linear-gradient(top, #007bc6, #0071b8);
    background-image: linear-gradient(to bottom, #007bc6, #0071b8);
    text-shadow: 0 1px hsla(0, 0%, 0%, 0.5);
    position: relative;
  }
}

.form-actions .dropbutton-multiple.open .dropbutton-widget {
  background-image: -webkit-linear-gradient(top, #007bc6, #0071b8);
  background-image: linear-gradient(to bottom, #007bc6, #0071b8);
}

.js .form-actions .dropbutton-wrapper .dropbutton-widget {
  .dropbutton-action .button {
    &:hover, &:focus {
      background-color: #2369a6;
      background-image: -webkit-linear-gradient(top, #0c97ed, #1f86c7);
      background-image: linear-gradient(to bottom, #0c97ed, #1f86c7);
      box-shadow: 0 1px 2px hsla(203, 10%, 10%, 0.25);
      color: #fff;
    }
    &:active {
      background-image: -webkit-linear-gradient(top, #08639b, #0071b8);
      background-image: linear-gradient(to bottom, #08639b, #0071b8);
      border-color: #144b78;
      box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.2);
    }
  }
  .dropbutton-toggle button {
    border-color: #1e5c90;
  }
}

.form-actions .dropbutton .secondary-action {
  border-color: #1e5c90;
}

.js .form-actions .dropbutton-wrapper .dropbutton-widget .dropbutton-toggle button {
  background-image: -webkit-linear-gradient(top, #007bc6, #0071b8);
  background-image: linear-gradient(to bottom, #007bc6, #0071b8);
  &:hover, &:focus {
    background-color: #2369a6;
    background-image: -webkit-linear-gradient(top, #0c97ed, #1f86c7);
    background-image: linear-gradient(to bottom, #0c97ed, #1f86c7);
  }
  &:active {
    background-image: -webkit-linear-gradient(top, #08639b, #0071b8);
    background-image: linear-gradient(to bottom, #08639b, #0071b8);
    border-color: #144b78;
    box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.2);
  }
}

.form-actions {
  .dropbutton-arrow {
    border-top-color: #fff;
  }
  .dropbutton-multiple.open .dropbutton-arrow {
    border-bottom: 0.3333em solid white;
  }
}
